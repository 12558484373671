import { render, staticRenderFns } from "./Productividad.vue?vue&type=template&id=376aff75&scoped=true"
import script from "./Productividad.vue?vue&type=script&lang=js"
export * from "./Productividad.vue?vue&type=script&lang=js"
import style0 from "./Productividad.vue?vue&type=style&index=0&id=376aff75&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "376aff75",
  null
  
)

export default component.exports